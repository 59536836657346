<template>
    <div class="banner" :style="styles">
        <video v-if="video" :src="video" autoplay muted loop></video>
        <div class="banner-text">
            <title-green :title="title"></title-green>
            <div class="summary">{{ summary }}</div>
        </div>

    </div>
</template>

<script>
    import TitleGreen from "./TitleGreen";

    export default {
        name: "LBanner",
        components: {TitleGreen},
        props: {
            title: String,
            summary: String,
            backgroundImage: String,
            video: String
        },
        data() {
            return {
                styles: {
                    backgroundImage: `url(${this.backgroundImage})`,
                }
            };
        }
    }
</script>

<style lang="less" scoped>
    .banner {
        height: 800px;
        overflow: hidden;
        background-size: contain;
        position: relative;
    }

    .banner-text {

        width: 1200px;
        padding-top: 278px;
        margin: 0 auto;

        .summary {
            font-size: 24px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 50px;
            margin-top: 30px;
        }
    }

    video {
        position: absolute;
        left: 0;
        top: 0;
        min-width: 100%;
        min-height: 100%;
        z-index: -1;
    }
</style>
<template>
    <div>
        <title-green class="title"
                     color="rgba(51, 51, 51, 1)"
                     title="场景应用"
                     text-background="https://static.ycsd.work/web/ai/t2.png"
        ></title-green>

        <div class="photo-container">
            <div class="white-bg"></div>
            <div class="photo-box">
                <div class="photo">
                    <img ondragstart="return false" :src="this.banners[this.activeIndex].image" alt="">
                    <div class="photo-sc"></div>
                    <div class="photo-title">
                        {{ this.banners[this.activeIndex].title }}
                    </div>
                </div>
                <div class="photo-buttons">
                    <div v-for="(banner,k) in banners" :key="k">
                        <span @click="active(k)" :class="{active:banner.active}">{{ banner.btn }}</span>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import TitleGreen from "./TitleGreen";

    export default {
        name: "ScenApp",
        components: {TitleGreen},
        data() {
            return {
                activeIndex: 0,
                banners: [
                    {
                        image: 'https://static.ycsd.work//site/appsphoto1.png',
                        title: '个人穿搭风格诊断',
                        btn: '形象管理',
                        active: true
                    },
                    {
                        image: 'https://static.ycsd.work//site/apps/s11.png',
                        title: '社群会员增值服务',
                        btn: '私域服务'
                    },
                    {
                        image: 'https://static.ycsd.work//site/apps/s22.png',
                        title: '提高用户活跃黏性',
                        btn: '电商推荐'
                    }
                ]
            }
        },
        methods: {
            active(index) {
                this.activeIndex = index;

                this.banners.map((item,key) => {
                    item.active = (key === index)
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .title {
        margin-bottom: 100px;
    }

    .photo-container {
        position: relative;
        background: #333333;
        padding-bottom: 109px;
    }

    .white-bg {
        width: 100%;
        position: absolute;
        left: 0;
        top: -1px;
        height: 340px;
        background-color: white;
        z-index: 1;
    }

    .photo-box {
        width: 1196px;
        z-index: 5;
        position: relative;
        text-align: center;
        margin: 0 auto;

        img {
            width: 100%;
            height: 690px;

            background: linear-gradient(129deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.25) 100%);
        }
    }

    .photo-buttons {
        padding-top: 60px;
        padding-bottom: 25px;
        background-image: url("../assets/images/ai/t3.png");
        background-size: 100% 100%;

        div {
            font-size: 40px;
            font-weight: 500;
            color: rgba(153, 153, 153, 1);
            line-height: 56px;
            display: inline-block;
            padding-left: 72px;
            padding-right: 72px;
            position: relative;

            span {
                display: block;
                cursor: pointer;

                &.active {
                    color: rgba(249, 249, 249, 1);
                }
            }

            &:after {
                content: ' ';
                width: 10px;
                height: 10px;
                display: block;
                background: #FFFFFF;
                position: absolute;
                right: 0;
                top: 50%;
                margin-top: -5px;
                border-radius: 50%;
            }

            &:last-child:after {
                display: none;
            }

        }
    }

    .photo {
        position: relative;
    }

    .photo-title {
        font-size: 36px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 50px;
        position: absolute;
        bottom: 60px;
        width: 100%;
        left: 0;
        padding-bottom: 20px;

        &:after {
            content: ' ';
            height: 2px;
            background-color: white;
            width: 120px;
            position: absolute;
            bottom: 0;
            left: 50%;
            margin-left: -60px;
        }
    }

    .photo-sc {
        width: 100%;
        height: 200px;
        position: absolute;
        left: 0;
        bottom: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%, rgba(0, 0, 0, 0.4) 100%);
    }

</style>
<template>
    <div class="patent-container">
        <title-green class="patent-title" title="专利技术"
                     text-background="https://static.ycsd.work//site/appst4.png"></title-green>

        <div class="patent-banners">

            <div id="patent-swiper" ref="patent-swiper" class="swiper-container">
                <div class="swiper-wrapper">

                    <div class="swiper-slide">
                        <div class="patent-img">
                            <img src="@/assets/images/ai/zl1.png" alt="">
                        </div>
                    </div>

                    <div class="swiper-slide">
                        <div class="patent-img">
                            <img src="@/assets/images/ai/zl1.png" alt="">
                        </div>
                    </div>

                    <div class="swiper-slide">
                        <div class="patent-img">
                            <img src="@/assets/images/ai/zl1.png" alt="">
                        </div>
                    </div>

                    <div class="swiper-slide">
                        <div class="patent-img">
                            <img src="@/assets/images/ai/zl1.png" alt="">
                        </div>
                    </div>

                    <div class="swiper-slide">
                        <div class="patent-img">
                            <img src="@/assets/images/ai/zl1.png" alt="">
                        </div>
                    </div>

                </div>
            </div>


        </div>

    </div>
</template>

<script>
    import TitleGreen from "./TitleGreen";
    import 'swiper/swiper-bundle.min.css'
    import Swiper from 'swiper/swiper-bundle.min'

    export default {
        name: "Patent",
        components: {TitleGreen},
        data() {
            return {
                swiper: null,
                activeIndex: 0,
            }
        },
        mounted() {
            this.initSwiper()
        },
        methods: {
            initSwiper() {
                this.swiper = new Swiper('#patent-swiper', {
                    effect: 'coverflow',
                    slidesPerView: 'auto',
                    grabCursor: true,
                    loop: true,
                    centeredSlides: true,
                    coverflowEffect: {
                        rotate: 20,
                        stretch: -70,
                        depth: 30,
                        modifier: 1,
                        slideShadows: true,
                    },
                    on: {
                        slideChangeTransitionEnd: this.slided
                    },
                });
            },
            slided(swiper) {

                let index = swiper.activeIndex
                console.log(index);

            },
        }
    }
</script>

<style lang="less" scoped>
    .patent-container {
        padding: 127px 360px 0;
        background-color: rgba(16, 16, 16, 1);
        width: 100%;
        overflow: hidden;
    }

    .swiper-container {
        width: 1200px;
        padding-top: 50px;
        overflow-x: hidden;
        margin: 0 auto;
    }

    .patent-title {
        margin-bottom: 145px;
    }

    .swiper-slide {
        text-align: center;
        width: 345px;
    }

    .swiper-slide-active .patent-img {
        transform: scale(0.94);
    }

    .patent-img {
        width: 345px;
        position: relative;
        img{
            width: 100%;
        }

        &:after {
            content: ' ';
            width: 100%;
            height: 259px;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #101010 100%);
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
</style>
<template>
    <div>
        <main-nav fixed></main-nav>
        <l-right-contact></l-right-contact>

        <l-banner
                title="AI形象风格检测"
                summary="YOCHUR 独立技术研发的“AI人工智能形象分析检测”工具，科技结合理论，人脸算法结合时尚，
为数字化新经济形式注入新的力量，让全民穿搭省时高效，带来国民形象美学新变革。"
                video="https://static.ycsd.work//site/ai/banner-bg.mp4"
        ></l-banner>

        <div class="compared">
            <div class="w1200">
                <img class="title-img" src="@/assets/images/ai/ft1.png" alt="">
                <img class="comp-img" src="@/assets/images/ai/comp.png" alt="">


                <a-popover placement="bottom">
                    <template slot="content">
                        <img class="kf-qr" src="@/assets/images/xcx.png" alt="">
                    </template>
                    <template slot="title">
                        <span>微信扫一扫测试你的风格</span>
                    </template>
                    <a-button class="lr-button">立即体验</a-button>
                </a-popover>

            </div>
        </div>

        <div class="ant">
            <div class="w1200">
                <img class="title-img" src="@/assets/images/ai/ft2.png" alt="">
                <img class="comp-img" src="@/assets/images/ai/ant.png" alt="">
            </div>
        </div>

        <div class="alg">
            <div class="w1200">
                <img class="title-img" src="@/assets/images/ai/ft3.png" alt="">
                <img class="content-img" src="@/assets/images/ai/alg1.png" alt="">
                <img class="content-img" src="@/assets/images/ai/alg2.png" alt="">
                <img class="content-img" src="@/assets/images/ai/alg3.png" alt="">
                <img class="content-img" src="@/assets/images/ai/alg4.png" alt="">
                <img class="content-img" src="@/assets/images/ai/alg5.png" alt="">
                <img class="content-img" src="@/assets/images/ai/alg6.png" alt="">
                <img class="content-img" src="@/assets/images/ai/alg7.png" alt="">
            </div>
        </div>

        <scen-app></scen-app>
        <patent></patent>
        <l-footer></l-footer>
        <l-icp></l-icp>
    </div>
</template>

<script>
    import LIcp from "../../components/LIcp";
    import MainNav from "../../components/MainNav";
    import LFooter from "../../components/LFooter";
    import Patent from "../../components/Patent";
    import ScenApp from "../../components/ScenApp";
    import LBanner from "../../components/LBanner";
    import LRightContact from "../../components/LRightContact";
    import meta from "../../meta";

    export default {
        name: "FaceStyleDetection",
        components: {LRightContact, LBanner, ScenApp, Patent, LFooter, MainNav, LIcp},
        metaInfo: meta.appendTitle('AI形象风格检测')
    }
</script>

<style lang="less" scoped>
    .compared {
        background-image: url("../../assets/images/ai/bg1.png");
        background-repeat: no-repeat;
        background-size: contain;

        padding: 150px 0;
        text-align: center;
        background-color: #0a0503;

        .title-img {
            margin-bottom: 100px;
            width: 573px;
        }

        .comp-img {
            width: 1200px;
            max-width: 100%;
            margin-bottom: 50px;
        }
    }

    .w1200 {
        width: 1200px;
        max-width: 100%;
        margin: 0 auto;

        img {
            width: 100%;
        }
    }

    .ant {
        background-image: url("../../assets/images/ai/bg2.png");
        background-repeat: no-repeat;
        background-position: center;

        padding: 150px 0 270px;
        text-align: center;
        background-color: #0a0503;

        .title-img {
            width: 683px;
            margin-bottom: 150px;
        }
    }

    .alg {
        padding: 100px 0;

        .title-img {
            width: 662px;
        }

        .content-img {
            width: 100%;
        }

        img {
            margin-bottom: 200px;

            &:last-child {
                margin-bottom: 158px;
            }
        }
    }

    .kf-qr {
        width: 200px;
    }
    .lr-button{
        height: auto;
        background: #1F7762;
        font-size: 16px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 22px;
        border: 0;
        border-radius: 0;
        padding: 10px 28px;
    }
</style>